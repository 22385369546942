// https://vecta.io/symbols/tag/redis
// https://www.iconfinder.com/

const iconFolder = require.context('../icons/', true, /.*(svg|png)$/)
const iconPath = (name) => iconFolder(name, true)

const icon = function(id, get_svg) {
    var asset = `./assets/${id}`
    if (get_svg) {
        asset += '.svg';
    } else {
        asset += '.png';
    }
    try {
        return iconPath(asset)
    } catch {
        return ''
    }
}

export default icon;