// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import icon from "./icons/function";

//require("@rails/ujs").start()
//require("@rails/activestorage").start()
//require("channels")
require("better_deferred")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const readBtns = () => {
    $('.readbtn').on('click', function() {
        $(this).toggleClass('collapsed')
    });
}

/**
 * This function is useful if Lozas isn't used. Otherwise Lozad completes the same functionality using "data-src"
 *  Example: <img class="img-fluid lozad" data-src-icon-custom="<%= 'web_development' %>">
 */
const updateIconImages = () => {
    let images = $("img[data-src-icon-custom]").filter(function() {
        return $(this).attr('data-src-icon-custom');
    });

    images.each(function( index, element ) {
        let elem = $(element);
        elem.attr('src', icon(elem.attr('data-src-icon-custom'), true));
    });
}

const observer = () => {
    let observer = window.lozad(); // lazy loads elements with default selector as '.lozad'
    observer.observe();
}

const preparePage = () => {
    readBtns();
    updateIconImages();
}

$(document).ready(function() {
    observer();
    let path = window?.location?.pathname;
    switch (path) {
        default:
            preparePage();
            break;
    }

    if (typeof AOS != 'undefined') {
        AOS.init();
    }

    if (typeof HSStickyBlock != 'undefined') {
        // INITIALIZATION OF STICKY BLOCKS
        // =======================================================
        $('.js-sticky-block').each(function () {
            new HSStickyBlock($(this)).init();
        });
    }

    if (typeof HSScrollNav != 'undefined') {
        // INITIALIZATION OF SCROLL NAV
        // =======================================================
        $('.js-scroll-nav').each(function (elem, id) {
            new HSScrollNav($(this)).init();
        });
    }

    if (typeof HSGoTo != 'undefined') {
        // INITIALIZATION OF GO TO
        // =======================================================
        $('.js-go-to').each(function () {
            new HSGoTo($(this)).init();
        });
    }

    if (typeof HSHeader != 'undefined') {
        // INITIALIZATION OF HEADER
        // =======================================================
        $('.js-header').each(function (elem, id) {
            new HSHeader(elem).init();
        });
    }

    if (typeof HSMegaMenu != 'undefined') {
        // INITIALIZATION OF MEGA MENU
        // =======================================================
        new HSMegaMenu($('.js-mega-menu'), {
            breakpoint: 'sm'
        }).init();
    }
});
